<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="height: 100%;">
      <div class="modal-content" style="height: 100%;">
        <div class="modal-header">
          <div class="title-with-icon">
            <div class="title-with-icon__icon title-with-icon__icon_library"></div>
            <div v-if="title" class="title-with-icon__title">{{ title }}</div>
          </div>
          <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 100%;">
          <vue-pdf-app :id="'pdf-' + id" v-if="pdfLink" :download="false" style="height: calc(100% - 64px);" :pdf="pdfLink"></vue-pdf-app>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import $ from 'jquery';
import 'bootstrap'
window.JQuery = $
export default {
  name: "ModalPdfView",
  components: { VuePdfApp },
  data() {
    return {
      pdfLink: null
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    link: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  mounted() {
    let self = this
    $('#' + this.id).on('shown.bs.modal', function () {
      self.pdfLink = self.link
    })
    $('#' + this.id).on('hidden.bs.modal', function () {
      self.pdfLink = null
    })
  }
}
</script>

<style lang="scss">
  #download, #scaleSelectContainer, #openFile, #viewBookmark, #print {
    display: none!important;
  }
</style>